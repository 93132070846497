<template lang="pug" functional>
  div
    .employee-item-skeleton(
      v-for="i in props.count"
      :key="`emp-skl-${i}`"
      :class="{ 'employee-item-skeleton--small': props.small }")
      .employee-item-skeleton__checkbox(
        v-if="props.checkbox")
      .employee-item-skeleton__avatar
      .employee-item-skeleton__content
        .employee-item-skeleton__title
        .employee-item-skeleton__subtitle

</template>

<script>
export default {
  name: 'EmployeeItemSkeleton',

  props: {
    small: Boolean,
    checkbox: Boolean,
    count: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_mixins.scss';

  .employee-item-skeleton {
    padding: 1rem;
    display: flex;
    align-items: center;

    &--small {
      margin-bottom: 1px;
    }

    &:first-child {
      // padding-top: 0;
    }

    &:last-child {
      // padding-bottom: 0;
    }

    &__checkbox {
      height: 18px;
      width: 18px;
      margin-right: 10px;
      border-radius: 4px;
      @include skeleton;
    }

    &__avatar {
      height: 40px;
      width: 40px;
      margin-right: 1rem;
      border-radius: 50%;
      @include skeleton;
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    &__title {
      width: 140px;
      height: 15px;
      margin-bottom: 6px;
      border-radius: 5px;
      @include skeleton;
    }

    &__subtitle {
      width: 90px;
      height: 10px;
      border-radius: 5px;
      @include skeleton;
    }

    &--small &__avatar {
      height: 34px;
      width: 34px;
    }
  }
</style>
