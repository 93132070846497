<template lang="pug">
  .insight-detail__users
    v-scrollable(
      updatable
      :bottom-offset="77"
      @scroll-complete="onScrollEnd")
      .insight-detail__users__table
        v-table
          v-table-head
            th(style="width:50px")
            th.table-sort-arrows-hover(
              @click="onSortEmployees('name', sortedLabels.name)")
              | {{ 'ui.labels.name' | translate }}
              v-table-sorter(
                :show="currentSortLabel === 'name'"
                :toggled="sortedLabels.name")
            th.table-sort-arrows-hover(
              v-if="activeBadge.mainBadge"
              @click="onSortEmployees('levelName', sortedLabels.levelName)")
              | {{'ui.labels.level' | translate }}
              v-table-sorter(
                :show="currentSortLabel === 'levelName'"
                :toggled="sortedLabels.levelName")
            th(style="width:50px")

          v-table-body(
            :cols="3"
            :empty="!users.length"
            :loaded="loaded")
            v-table-row(v-for="(item, index) in users" :key="index")
              td.text-center
                v-avatar(:src="item.profilePictureUrl")
              td {{ item.lastName  + ' ' + item.firstName }}
              td(v-if="activeBadge.mainBadge") {{ item.lastActivatedChildBadge.levelName  }}
              td.text-right
                v-btn(
                  icon
                  v-tooltip="$t('ui.labels.open_profile')"
                  @click="openUserInNewWindowTab(item.id)")
                  i.fa.fa-external-link-alt
        .insight-detail__users__skeleton(v-if='isPreloadPagination')
          v-skeleton(
            v-for="i in 3"
            :key="i"
            width="100%"
            height="66px")
    .insight-detail__users__footer
      span {{ usersLength }} {{ 'ui.labels.users' | translate }}
</template>

<script>
import EmployeeItemSkeleton from '@/components/common/lists/EmployeeItemSkeleton'

export default {
  name: 'BadgeDataUsers',

  components: {
    EmployeeItemSkeleton
  },

  props: {
    getEmployees: Function,
    activeBadge: Object
  },

  async mounted () {
    this.init()
  },

  data: () => ({
    loaded: true,
    users: [],
    usersLength: 0,
    page: 1,
    blockScroll: true,
    isPreloadPagination: false,
    currentSortLabel: 'name',
    sortedLabels: {
      name: true,
      levelName: false
    },
    currentSortParams: {
      sort: 'name'
    }
  }),

  methods: {
    async init () {
      this.blockScroll = true
      this.page = 1
      this.usersLength = 0
      this.users = []
      this.loaded = false
      await this.loadUsers()
      this.loaded = true
    },

    async loadUsers () {
      const filters = {}
      filters.page = { value: this.page }
      filters.sort = { value: this.currentSortParams.sort }

      const { data, headers } = await this.getEmployees({
        filters,
        badgeId: this.activeBadge.id,
        isAll: this.activeBadge.mainBadge
      })
      if (data) {
        this.users = [...this.users, ...data]
        this.isPreloadPagination = false
        this.usersLength = headers['x-pagination-total-count']
        if (+headers['x-pagination-page-count'] > this.page) {
          this.blockScroll = false
        } else {
          this.blockScroll = true
        }
      }
      return data
    },

    onScrollEnd () {
      if (!this.blockScroll) {
        this.isPreloadPagination = true
        this.blockScroll = true
        this.page++
        this.loadUsers()
      }
    },

    openUserInNewWindowTab (userId) {
      const routeData = this.$router.resolve({ name: `/employee/${userId}` })
      window.open(routeData.route.name, '_blank')
    },

    onSortEmployees (fieldName, order) {
      const params = {
        sort: order ? `-${fieldName}` : fieldName
      }
      this.currentSortParams = JSON.parse(JSON.stringify(params, {}))
      this.init()
      for (const key in this.sortedLabels) {
        if (key === fieldName) {
          this.sortedLabels[key] = !this.sortedLabels[key]
          this.currentSortLabel = fieldName
        } else {
          this.sortedLabels[key] = false
        }
      }
    }
  },

  watch: {
    activeBadge () {
      this.init()
    }
  }
}
</script>

<style lang="scss">

  .insight-detail__users {
    &__table{
      display: flex;
      flex-direction: column;
    }

    &__footer{
      padding-top: 1rem;
    }

    &__skeleton {
      .v-skeleton {
        margin-top: 10px;
      }
    }
  }
</style>
